<template>
  <div class="about">
    <div class="container">
      <section class="about-journey">
        <h2>Anuncie na imoGo!</h2>
        <p>Escolha a sua categoria e siga passo a passo para realizar o cadastro do seu imóvel.</p>
        <div class="tabs">
          <div class="tab-buttons">
              <button
                  v-for="tab in tabs"
                  :key="tab.id"
                  :class="['tab-button', { active: currentTab === tab.id }]"
                  :style="{ backgroundColor: getColor(tab.id, currentTab) }"
                  @click="currentTab = tab.id">
                  {{ tab.title }}
              </button>
          </div>
          <div class="tab-contents">
              <div v-for="tab in tabs" :key="tab.id" :id="tab.id" class="tab-content" v-show="currentTab === tab.id">
                  <div class="content">
                    <div class="item" v-for="(content, index) in tab.content" :key="content">
                      <div v-if="index === (tab.content.length - 1)" class="item last-item" :style="{ background: getLastItemColor(tab.id) }">
                        <img src="~@/assets/icons/icon-check.svg" alt="" />
                        <label>{{ content }}</label>
                      </div>
                      <div v-else>
                        <h1 :style="{ color: getColor(tab.id, currentTab) }" >{{ index + 1 }}</h1>
                        <label>{{ content }}</label>
                      </div>
                  </div>
                  <div class="footer">
                    <h2>Baixe e se cadastre no app:</h2>
                    <div>
                      <a :href="tab.appiOS" target="_blank">
                        <img alt="" src="~@/assets/logo-apple.svg" />
                      </a>
                      <a :href="tab.appAndroid" target="_blank">
                        <img alt="" src="~@/assets/logo-android.svg" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <h1>Descomplica, vem de imoGo</h1>
      <section class="about-header">
        <div class="about-content">
          <p>A imoGo é uma startup de soluções imobiliárias inteligentes. Nossa história começa unindo a expertise de grandes nomes do setor imobiliário à necessidade de incorporar tecnologia, praticidade e rapidez aos processos de compra e venda de imóveis.Em pleno 2023, ninguém aguenta lidar com tanta demora e burocracia para tratar com vendas e papeladas.Nascemos para resolver de uma vez por todas estas dores. </p>
          <p>Saiba com um clique o preço real de seu imóvel, resolva compra e venda através do seu celular, sem precisar ir a lugar algum. Tenha à disposição profissionais que irão te auxiliar rapidamente em todas as suas necessidades de compra ou venda e fique livre da burocracia do mercado imobiliário tradicional. Pode entrar sem bater!</p>
          <div>
              <a href="https://apps.apple.com/br/app/imogo/id1661571220" target="_blank">
                <img alt="" src="~@/assets/logo-apple.svg" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=br.com.politiz.qi&pcampaignid=web_share" target="_blank">
                <img alt="" src="~@/assets/logo-android.svg" />
              </a>
          </div>
          <div class="qrCode">
            <img alt="" src="~@/assets/imogoapp.png" />
          </div>
        </div>
        <div class="about-image">
          <img alt="" src="~@/assets/image-about.png">
        </div>
      </section>
      <section class="about-main">
        <h2>Vem descomplicar também!</h2>

        <div class="about-boxes">
          <div class="about-box">
            <img alt="" src="~@/assets/image-mission.svg">
            <h3>Modernidade</h3>
            <p>Somos a inovação do mercado imobiliário! A imoGo utiliza a mais moderna tecnologia e inteligência artificial, serviços otimizados e facilitadores na hora de vender imóveis. Aqui, a burocracia não tem vez!</p>
          </div>
          <div class="about-box">
            <img alt="" src="~@/assets/image-vision.svg">
            <h3>Oportunidades</h3>
            <p>Sabemos que o mercado de imóveis é dinâmico e todos os dias surgem novas oportunidades. Entendemos a importância de conhecer preço, demanda e oferta de cada localização. Conte com a imoGo para te guiar nessa jornada de valor!</p>
          </div>
          <div class="about-box">
            <img alt="" src="~@/assets/image-values.svg">
            <h3>Inovação</h3>
            <p>Venha fazer parte desta revolução e deixe que a gente cuida de tudo. Acompanhe de onde estiver, apenas consultando o status de suas negociações através no app imoGo. Agora é muito mais fácil!</p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  metaInfo: {
    title: 'imoGo - Sobre'
  },
  data () {
    return {
      currentTab: 'tab1',
      tabs: []
    }
  },

  mounted () {
    this.tabs = [
      {
        id: 'tab1',
        title: 'Proprietário',
        content: ['Faça seu cadastro pessoal', 'Avalie o imóvel', 'Cadastre o imóvel e autorize a venda', 'Aguarde a análise dos documentos', 'Visita fotográfica', 'Imóvel publicado!'],
        appiOS: 'https://apps.apple.com/br/app/imogo-vendedor/id6447977587',
        appAndroid: 'https://play.google.com/store/apps/details?id=br.com.politiz.qi.owner'
      },
      {
        id: 'tab2',
        title: 'Corretor',
        content: ['Faça seu cadastro pessoal', 'Avalie o imóvel', 'Cadastre o imóvel e indique o vendedor', 'Peça ao vendedor que autorize a venda', 'Aguarde a análise documental', 'Visita fotográfica', 'Imóvel publicado!'],
        appiOS: 'https://apps.apple.com/br/app/imogo/id1661571220',
        appAndroid: 'https://play.google.com/store/apps/details?id=br.com.politiz.qi&pcampaignid=web_share'
      }
    ]
  },

  methods: {
    getColor (tabId, currentTab) {
      switch (tabId) {
        case 'tab1':
          return currentTab === tabId ? 'var(--orange-custom)' : 'var(--white)'
        case 'tab2':
          return currentTab === tabId ? 'var(--green-custom)' : 'var(--white)'
        default:
          return 'var(--green-custom)'
      }
    },
    getLastItemColor (tabId) {
      switch (tabId) {
        case 'tab1':
          return 'radial-gradient(circle, #E17B34, #D65030)'
        case 'tab2':
          return 'radial-gradient(circle, #C117DB, #730D83)'
        default:
          return 'var(--green-custom)'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .about {
    padding: 0px 0 80px;

    h1 {
      font-size: 62px;
      line-height: 64px;
      font-weight: 300;
      margin: 80px 0 86px;
      max-width: 650px;
      color: #2F2F33;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
      color: #2F2F33;
      font-weight: 300;
      margin-bottom: 32px;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #757680;
      margin-bottom: 16px;
      span {
        font-weight: 500;
      }
    }

    .container {
      max-width: 1200px;
    }

    .about-journey {
      margin-top: 60px;

      h2 {
        font-size: var(--size-h2);
        font-weight: 600;
        margin-bottom: 8px;
      }

      .tabs {
          max-width: 100%;
          margin: 40px auto;
      }

      .tab-buttons {
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .tab-button {
          max-width: 12%;
          margin-left: 20px;
          flex: 1;
          padding: 14px;
          border: none;
          background-color: var(--white);
          cursor: pointer;
          text-align: center;
          border-bottom: 2px solid transparent;
          transition: background-color 0.3s, border-bottom 0.3s;
          border-radius: 20px;
          font-weight: 800;
      }

      .tab-button.active {
          color: var(--white);
      }

      .tab-contents {
          padding: 20px;
      }

      .tab-content {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .content {
          display: flex;
          flex-wrap: wrap;
          max-width: 100%;
          gap: 20px; /* Espaçamento entre os itens */
        }

        .footer {
          min-width: 65%;

          h2 {
            font-size: var(--size-large)
          }
        }

        .item {
            background-color: white;
            flex: 1 1 calc(33.333% - 20px); /* Três itens por linha com espaçamento */
            box-sizing: border-box;
            text-align: center;
            border-radius: 20px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

            div {
              min-height: 180px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 30px;
            }

            &.last-item {
              color: white;
            }

            img {
              width: 70px;
              height: 70px;
            }

            h1 {
              font-size: var(--size-h1);
              font-weight: bold;
              color: var(--green-custom);
              line-height: 64px;
              margin: 8px;
            }

            label {
              font-size: 14px;
              font-weight: 400;
            }
        }
      }
    }

    .about-main {
      margin-top: 200px;

      h2 {
        font-size: var(--size-h2);
        line-height: 48px;
        color: var(--green-custom);
        font-weight: 300;
      }

      .about-boxes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;

        .about-box {
          background: var(--white-smoke);
          border-radius: 25px;
          padding: 32px;
          max-width: 416px;
          min-height: 566px;
          width: 100%;

          img {
            margin-bottom: 70px;
          }

          h3 {
            color: #2E2F32;
            font-size: var(--size-h2);
            font-weight: 500;
            font-size: 32px;
            margin-bottom: 24px;
            line-height: 36px;
          }

          ul {
            list-style: none;
          }

          p,
          li {
            font-weight: 300;
            font-size: 18px;
            line-height: 40px;
            color: #414F54;
            padding: 0 0;
            margin: 0 0;
          }
        }
      }
    }

    .about-second {
      img {
        width: 100%;
      }
    }

    .about-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 40px;

      p {
        color: var(--atomic);
        font-weight: 300;
        font-size: 20px;
        line-height: 35px;
        max-width: 640px;
        margin: 0 0 30px;
      }

      .about-image {
        display: flex;
        justify-content: center;
        position: relative;
        max-width: 640px;
        width: 100%;

        img {
          max-height: 750px;
          max-width: 650px;
          height: auto;
        }
      }

      .qrCode {

        img {
          width: 200px;
          height: 200px;
          padding: 8px;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 80px 0 60px;

      h1 {
        font-size: 46px;
        line-height: 56px;
        margin: 0px 0 30px;
      }

      h2 {
        margin-bottom: 32px;
      }

      .about-boxes {
        flex-direction: column;
      }

      .about-header {
        flex-direction: column;
        .about-image {
          img {
            height: auto;
            max-height: unset;
            width: 100%;
          }
        }
      }

      .about-journey {
        .tab-button {
          max-width: 100%;
        }

        .tab-content {
          .content {
            gap: 20px;
          }

          .item {
            flex: 1 1 100%; /* Um item por linha em telas muito pequenas */
          }
        }
      }
    }

  }
</style>
